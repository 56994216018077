import { Post, State } from "@/models/post";

export type method = 'get' | 'post' | 'put' | 'delete';

class Api {
    private url = `${process.env.VUE_APP_BACKEND_URL}/api`;

    /**
     * Gets the current saldo on the account.
     * @returns The current saldo on the account.
     */
    public async getCurrentState(): Promise<State> {
        const currentState = await this.request<State>('get', 'state');
        return currentState;
    }

    public async getPost(id: string): Promise<Post | undefined> {
        const post = await this.request<Post>('get', `post/${id}`);
        return Promise.resolve(post);
    }

    /**
     * Gets the selected amount of posts from the database.
     * @param fromIndex The index of the first post to return.
     * @param length The number of posts to retrieve.
     * @returns Retrieves the selected posts from the database.
     */
    public async getPosts(fromIndex: number = 0, length: number): Promise<Post[]> {
        const posts = await this.request<Post[]>('get', `post/${fromIndex}/${length}`);
        return posts;
    }

    public async updatePost(post: Post): Promise<Post> {
        const result = await this.request<Post>('put', `post/${post.id}`, post);
        return result;
    }

    public async deletePost(id: string): Promise<void> {
        return this.request<void>('delete', `post/${id}`);
    }

    private async request<T>(method: method, action: string, data?: any): Promise<T> {
        // Create the request
        const url = `${this.url}/${action}`;
        const body = data == null ? undefined : JSON.stringify(data);
        const request = new Request(url, {
            method,
            body,
            headers: {
                'Content-Type': 'application/json',
            }
        });

        // Send the request
        const response = await fetch(request);

        // If the response is not ok, throw an error
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        else if (response.status === 204) { // No content
            return {} as T;
        }
        else {
            return response.json();
        }
    }
}

const api = new Api();
export default api;