class FormatUtil {
    /**
     * Formats a date so it is shown relatively to the current date.
     * @param date The date to format.
     * @returns A relatively formated date.
     */
    public relativeDateFormat(date: Date | string) {
        const dateObj = new Date(date);
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const dateDay = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
        const daysAgo = Math.round((today.getTime() - dateDay.getTime()) / (1000 * 60 * 60 * 24));
    
        if (dateObj.getFullYear() !== now.getFullYear()) {
            return dateObj.toLocaleDateString();
        } else if (daysAgo > 7) {
            return dateObj.toLocaleDateString('sv-SE', {
                month: 'long',
                day: 'numeric' 
            });
        } else {   
            const rtf = new Intl.RelativeTimeFormat('sv-SE', {
                numeric: 'auto'
            });
            return rtf.format(0 - daysAgo, 'days'); // Returns "yesterday"
        }
    }
    
    /**
     * Formats the selected number to a currency format
     */
    currencyFormat(amount: number) {
        return amount.toLocaleString('sv-SE', {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 2
        });
    }

    public dateAndTimeFormat(date: Date | string) {
        const dateObj = new Date(date);
        return dateObj.toLocaleString('sv-SE', {
            dateStyle: 'short',
            timeStyle: 'short'
        });
    }

    public dateAndTimeDataFormat(date: Date | string) {
        const dateObj = new Date(date);
        const result = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}T${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}`;
        return result;
    }
}

const formatUtil = new FormatUtil();
export default formatUtil;
