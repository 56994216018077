import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Timeline from '@/views/Timeline.vue';
import Edit from '@/views/edit.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'timeline',
        component: Timeline
    },
    {
        path: '/edit/:id?',
        name: 'edit',
        component: Edit
    }
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
