<template>
    <!-- <nav>
<router-link to="/">Home</router-link> |
</nav> -->
    <h1>Veckopeng</h1>
    <router-view />
</template>

<style lang="scss">
#app {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $font-size-normal;
}
</style>
